export const customError = `
<style>
.error{
  display:flex;
  justify-content:center;
  margin-top:40vh;
}
.error-text{
  text-align:center;
}
</style>
<div class="error">
  <div class="error-text">
    <h4>Application is under maintenance</h4>
    <p>We expect to be back soon, Thanks for your patience</p>
  </div>
</div>`;