import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
import microfrontendLayout from "./microfrontend-layout.html";
import PubSub from "pubsub-js";
import { customError } from "./core/util";

/**
 * Global Fn - Publish
 * @param event 
 * @param data 
 * @returns 
 */
const publish = (event, data) => PubSub.publish(event, data);

/**
 * Global Fn - subscribe
 * @param event 
 * @param callback 
 * @returns 
 */
const subscribe = (event, callback) => PubSub.subscribe(event, callback);

/**
 * Global variable errorView
 */
const errorView = customError

/**
 * Global variable applicationData
 */
const applicationData = {
  loaders: {},
  props: {
    notifyEvents: { publish, subscribe },
  },
  errors: {
    errorView,
  },
};

/**
 * Global variable routes - single-spa routes
 */
const routes = constructRoutes(microfrontendLayout, applicationData);

/**
 * Gloable variable applications - single-spa application construction
 */
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});

/**
 * Gloable variable layoutEngine - single-spa layout construction
 */
const layoutEngine = constructLayoutEngine({ routes, applications });

/**
 * single-spa application creation
 */
applications.forEach(registerApplication);

/**
 * single-spa layout activation
 */
layoutEngine.activate();

/**
 * Function to start single-spa
 */
start();
